import clsx from 'clsx';
import {Link} from 'react-router-dom';
import ArrowSvg from '../../../../../assets/images/icons/arr079.svg';
// import LogoSvg from '../../../../../assets/images/logo.svg';
import LogoSvg from '../../../../../assets/images/polygon-logo.svg';
import {KTSVG} from '../../../../../_metronic/helpers';
import {useLayout} from '../../../../../_metronic/layout/core';
import {useAuth} from '../../../../modules/auth';
import {getHomePageRoute} from '../../../helpers/RouteHelpers';

const getToggleType = (isCollasped: boolean | undefined, isMinimized: boolean | undefined) => {
	if (isCollasped) return 'collapse';
	if (isMinimized) return 'minimize';
	return '';
};

const SidebarLogo = () => {
	const {config} = useLayout();
	const {currentUserInfo} = useAuth();
	const appSidebarDefaultMinimizeDesktopEnabled = config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
	const appSidebarDefaultCollapseDesktopEnabled = config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
	const toggleType = getToggleType(appSidebarDefaultCollapseDesktopEnabled, appSidebarDefaultMinimizeDesktopEnabled);
	const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : '';
	const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default;

	const canToggle = appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled;

	return (
		<div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo'>
			<Link to={getHomePageRoute(currentUserInfo)}>
				<div className='d-flex'>
					{config.layoutType === 'dark-sidebar' ? (
						<img alt='Logo' src={LogoSvg} className='h-50px app-sidebar-logo-default' />
					) : (
						<>
							<img alt='Logo' src={LogoSvg} className='h-50px app-sidebar-logo-default theme-light-show' />
							<img alt='Logo' src={LogoSvg} className='h-50px app-sidebar-logo-default theme-dark-show' />
						</>
					)}

					<img alt='Logo' src={LogoSvg} className='h-25px app-sidebar-logo-minimize' />

					{/* <span className='app-sidebar-logo-company-name'>
						<span style={{color: '#DF146E'}}>b</span>Kash
					</span> */}
					<span className='app-sidebar-logo-company-name'>
						<span style={{color: '#50C878'}}>P</span>olygontech
					</span>
				</div>
			</Link>

			{canToggle && (
				<div
					id='kt_app_sidebar_toggle'
					className={clsx(
						'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
						{active: appSidebarDefaultMinimizeDefault},
					)}
					style={{backgroundColor: '#FCE8F3 !important'}}
					data-kt-toggle='true'
					data-kt-toggle-state={toggleState}
					data-kt-toggle-target='body'
					data-kt-toggle-name={`app-sidebar-${toggleType}`}
				>
					<KTSVG path={ArrowSvg} className='svg-icon-2 rotate-180' />
				</div>
			)}
		</div>
	);
};

export {SidebarLogo};
