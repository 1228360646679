import React from 'react';
import clsx from 'clsx';
import {useLocation} from 'react-router-dom';
import {checkIsActive, WithChildren} from '../../../../../../_metronic/helpers';
import {useLayout} from '../../../../../../_metronic/layout/core';

type Props = {
	to: string;
	title: string;
	icon?: string;
	fontIcon?: string;
	hasBullet?: boolean;
};

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({children, to, title, icon, fontIcon, hasBullet}) => {
	const {pathname} = useLocation();
	const isActive = checkIsActive(pathname, to);
	const {config} = useLayout();
	const {app} = config;

	return (
		<div
			className={clsx('menu-item mb-2', {'here show': isActive}, 'menu-accordion')}
			style={{backgroundColor: isActive ? '#FCE8F3' : ''}}
			data-kt-menu-trigger='click'
		>
			<span className='menu-link' style={{backgroundColor: isActive ? '#FCE8F3' : ''}}>
				{hasBullet && (
					<span className='menu-bullet'>
						<span className='bullet bullet-dot' />
					</span>
				)}
				{icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
					<span className='menu-icon mx-2'>
						<img alt={title} src={icon} className='h-24px w-24px' />
					</span>
				)}
				{fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && <i className={clsx('bi fs-3', fontIcon)} />}
				<span className='menu-title text-black'>{title}</span>
				<span className='menu-arrow text-black' />
			</span>
			<div
				className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}
				style={{backgroundColor: isActive ? '#FCE8F3' : ''}}
			>
				{children}
			</div>
		</div>
	);
};

export {SidebarMenuItemWithSub};
